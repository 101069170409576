export default (text, isParagraph = false) => {
    let ret = '';

    if(typeof text === 'string') {
        ret = text;
    } else {
        for(let x = 0; x < text.length; x++) {
            for(let child = 0; child < text[x].children.length; child++) {
                ret += text[x].children[child].text;

                if(child < text[x].children.length - 1) {
                    ret += '\n';
                }
            }

            if(x < text.length - 1) {
                ret += '\n\n';
            }
        }
    }

    if(isParagraph) {
        return ret.replaceAll('\n\n', '</p><p>').replaceAll('\n', '<br />')
    }

    return ret.replaceAll('\n\n', '<br /><br />').replaceAll('\n', '<br />')
}