import linebreak from './linebreak'

export default (blocks = [], allowLineBreaks = false) => {
    blocks = blocks
    .map(block => {
        if(block._type !== 'block' || !block.children) {
            return ''
        }

        return block.children.map(child => child.text).join('')
    }).join('\n\n')

    if(allowLineBreaks) {
        return linebreak(blocks)
    }

    return blocks
}